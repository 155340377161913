//
//
//
//
//
//

// import * as sections from '~/components/sections';
import "~/components/filters.js";
import socialize from "~/components/social";

export default {
  head() {
    return socialize(this.page)
  },
  async asyncData({ app, store, $content, params }) {
    const page = await $content('pages',params.slug).fetch()
    store.commit('pages/set',page);

    return {
      page
    }
  }
}
